import { useState, useEffect, useRef } from 'react'
import { Grid } from '../components/grid/Grid'
import { Keyboard } from '../components/keyboard/Keyboard'
import { InfoModal } from '../components/modals/InfoModal'
import { StatsModal } from '../components/modals/StatsModal'
import { FactModal } from '../components/modals/FactModal'
import { SettingsModal } from '../components/modals/SettingsModal'
import { SignUpModal } from '../components/modals/SignUpModal'
import { LoginModal } from '../components/modals/LoginModal'
import { LoginWithEmailModal } from '../components/modals/LoginWithEmailModal'
import { LoginWithPinModal } from '../components/modals/LoginWithPinModal'
//import { AppPopUpModal } from '../components/modals/AppPopUp'
import {
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
} from '../constants/strings'
import {
  MAX_WORD_LENGTH,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  GAME_LOST_INFO_DELAY,
} from '../constants/settings'
import { isWordInWordList, unicodeLength } from '../lib/words'
import { ApiService } from '../lib/api'
import {
  addAPIStatsForCompletedGame,
  addStatsForCompletedGame,
  loadStats,
} from '../lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
  getStoredIsHighContrastMode,
} from '../lib/localStorage'
import { default as GraphemeSplitter } from 'grapheme-splitter'

import '../App.css'
import { AlertContainer } from '../components/alerts/AlertContainer'
import { useAlert } from '../context/AlertContext'
import { Navbar } from '../components/navbar/Navbar'
import Realistic from '../components/modals/Realistic'
import { DailyContent } from '../lib/types'
import { ThreeCircles } from 'react-loader-spinner'

interface UserAPIData {
  _id: string
  email: string
  firstName: string
  lastName: string
  allowsEmail: boolean
  isSuperUser: boolean
  gamesFailed: number
  successRate: number
  currentStreak: number
  bestStreak: number
  winDistribution: number[]
}

const Home = () => {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const params = window.location.search

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isFactModalOpen, setIsFactModalOpen] = useState(false)
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isLoginWithEmailModalOpen, setIsLoginWithEmailModalOpen] =
    useState(false)
  const [isLoginWithPinModalOpen, setIsLoginWithPinModalOpen] = useState(false)
  const [appIsReady, setAppIsReady] = useState(false)
  const [description, setDescription] = useState('')
  const [link, setLink] = useState('')
  const [linkName, setLinkName] = useState('')
  const [recipeImage, setRecipeImage] = useState('')
  const [lightLogoOR, setLightLogoOR] = useState('')
  const [darkLogoOR, setDarkLogoOR] = useState('')

  const [userToken, setUserToken] = useState<string>(() => {
    const userToken = localStorage.getItem('phoodle-user-token')
    if (userToken) {
      return userToken
    } else {
      return ''
    }
  })

  // const [isPopUpModalOpen, setIsPopUpModalOpen] = useState<boolean>(() => {
  //   const accountsPopUp = localStorage.getItem('isAccountsPopUpSeen')
  //   if (accountsPopUp === '1') {
  //     return false
  //   } else {
  //     return true
  //   }
  // })

  // const [isAppPopUpModalOpen, setIsAppPopUpModalOpen] = useState<boolean>(() => {
  //   const appPopUp = localStorage.getItem('isAppPopUpSeen')
  //   if (appPopUp === '1') {
  //     return false
  //   } else {
  //     return true
  //   }
  // })

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )

  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded) {
      return loaded.guesses
    } else {
      return []
    }
  })

  const [apiSolution, setApiSolution] = useState(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.apiSolution) {
      return loaded.apiSolution
    } else {
      return ''
    }
  })

  const [songUrl, setSongUrl] = useState<string>('')
  const [songTitle, setSongTitle] = useState<string>('')
  const [songArtist, setSongArtist] = useState<string>('')

  // Add state variables for music service URLs
  const [spotifyUrl, setSpotifyUrl] = useState<string>('')
  const [appleMusicUrl, setAppleMusicUrl] = useState<string>('')
  
  // Add ref for audio preloading
  const preloadAudioRef = useRef<HTMLAudioElement | null>(null)

  const setToken = (val: string) => {
    setUserToken(val)
  }

  useEffect(() => {
    async function getUserData(jwt: string) {
      if (jwt) {
        const apiService = new ApiService()
        try {
          const res = await apiService.getUserData(jwt)
          localStorage.setItem('phoodle-user-token', jwt)
          showSuccessAlert('LOGGED IN')
          setUserAPIData(res)
        } catch {
          showErrorAlert('Token invalid')
          setUserAPIData(undefined)
          setUserToken('')
          localStorage.setItem('phoodle-user-token', '')
        }
      }
    }

    if (params) {
      const jwt = params.substring(1).split('=')[1]
      if (jwt) {
        getUserData(jwt)
      }
    }
  }, [params, showErrorAlert, showSuccessAlert])

  useEffect(() => {
    const apiService = new ApiService()
    localStorage.removeItem('isPopUpSeen')
    const d = new Date()
    const date =
      d.getFullYear() +
      '-' +
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + d.getDate()).slice(-2)

    async function fetchWord() {
      const res: DailyContent = await apiService.getMysteryWord(date)

      const loaded = loadGameStateFromLocalStorage()

      // Original game logic without any testing overrides
      if (loaded?.apiSolution !== res.word.toUpperCase()) {
        setGuesses([])
        if (isGameWon) {
          setIsGameWon(false)
        }
        if (isGameLost) {
          setIsGameLost(false)
        }
      } else if (loaded.guesses.includes(res.word.toUpperCase())) {
        setIsGameWon(true)
      } else if (loaded.guesses.length === 6) {
        setIsGameLost(true)
      }

      setApiSolution(res.word.toUpperCase())
      setLink(res.recipeLink || '')
      setLinkName(res.recipeName || '')
      setRecipeImage(res.recipeImage || '')
      setDescription(res.description)
      setLightLogoOR(res.lightLogoOverride || '')
      setDarkLogoOR(res.darkLogoOverride || '')

      setSongUrl(res.songUrl || '')
      setSongTitle(res.songTitle || '')
      setSongArtist(res.songArtist || '')
      setSpotifyUrl(res.spotifyUrl || '')
      setAppleMusicUrl(res.appleMusicUrl || '')

      setAppIsReady(true)
    }

    fetchWord()
  }, [isGameLost, isGameWon])

  const [stats, setStats] = useState(() => loadStats())
  const [userAPIData, setUserAPIData] = useState<UserAPIData>()

  useEffect(() => {
    async function getUserData() {
      if (userToken) {
        const apiService = new ApiService()
        try {
          const res = await apiService.getUserData(userToken)
          setUserAPIData(res)
        } catch {
          showErrorAlert('You have been logged out')
          setUserAPIData(undefined)
          setUserToken('')
          localStorage.setItem('phoodle-user-token', '')
        }
      }
    }

    async function refreshJwt() {
      if (userToken) {
        const apiService = new ApiService()
        try {
          const res = await apiService.refreshJwt(userToken)
          localStorage.setItem('phoodle-user-token', res)
        } catch {
          showErrorAlert('Token expired, Please login again')
          setUserAPIData(undefined)
          setUserToken('')
          localStorage.setItem('phoodle-user-token', '')
        }
      }
    }

    getUserData()
    refreshJwt()
  }, [userToken, showErrorAlert])

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const logout = () => {
    setUserAPIData(undefined)
    setUserToken('')
    localStorage.setItem('phoodle-user-token', '')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, apiSolution })
  }, [guesses, apiSolution])

  useEffect(() => {
    if (isGameWon && guesses.length > 0) {
      const winMessage = WIN_MESSAGES[guesses.length - 1]
      const delayMs = REVEAL_TIME_MS * MAX_WORD_LENGTH

      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => {
          setIsFactModalOpen(true);
        }
      })
    }

    if (isGameLost) {
      setTimeout(() => {
        setIsFactModalOpen(true);
      }, GAME_LOST_INFO_DELAY)
    }
  }, [isGameWon, isGameLost, showSuccessAlert, guesses.length]);

  const isWinningWord = (guess: string) => {
    return apiSolution === guess
  }

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = async () => {
    const apiService = new ApiService()
    const winningWord = isWinningWord(currentGuess)

    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === MAX_WORD_LENGTH)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess) && !winningWord) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * MAX_WORD_LENGTH)

    if (
      unicodeLength(currentGuess) === MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        if (userAPIData) {
          const data = addAPIStatsForCompletedGame(userAPIData, guesses.length)
          if (data?.stats && data.statsForApi) {
            await apiService.editData(
              data.statsForApi,
              userAPIData._id,
              userToken
            )
            setUserAPIData({
              ...userAPIData,
              bestStreak: data.stats.bestStreak,
              currentStreak: data.stats.currentStreak,
              winDistribution: data.stats.winDistribution,
              gamesFailed: data.stats.gamesFailed,
              successRate: data.stats.successRate,
            })
          }
        }
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        if (userAPIData) {
          const data = addAPIStatsForCompletedGame(
            userAPIData,
            guesses.length + 1
          )
          if (data?.stats && data.statsForApi) {
            await apiService.editData(
              data.statsForApi,
              userAPIData._id,
              userToken
            )
            setUserAPIData({
              ...userAPIData,
              bestStreak: data.stats.bestStreak,
              currentStreak: data.stats.currentStreak,
              winDistribution: data.stats.winDistribution,
              gamesFailed: data.stats.gamesFailed,
              successRate: data.stats.successRate,
            })
          }
        }
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsGameLost(true)
        showSuccessAlert(CORRECT_WORD_MESSAGE(apiSolution), {
          delayMs: REVEAL_TIME_MS * MAX_WORD_LENGTH + 1,
        })
      }
    }
  }

  const fieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [
    isLoginModalOpen,
    isSignUpModalOpen,
    isLoginWithEmailModalOpen,
    isLoginWithPinModalOpen,
  ])

  // Add useEffect for audio preloading
  useEffect(() => {
    // Create an audio element for preloading if we have a song URL
    if (songUrl && !preloadAudioRef.current) {
      console.log('Preloading audio:', songUrl);
      const audio = new Audio(songUrl);
      
      // Ensure it's set to preload automatically
      audio.preload = 'auto';
      
      // Store the audio element
      preloadAudioRef.current = audio;
      
      // Start loading the audio
      audio.load();
    }
  }, [songUrl]);

  if (!appIsReady) {
    return (
      <div className="flex h-screen w-screen mx-auto my-auto justify-center items-center">
        <ThreeCircles
          height="100"
          width="100"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor="#a2c94c"
          innerCircleColor="#718d35"
          middleCircleColor="#b5d470"
        />
      </div>
    )
  }
  return (
    <>
      <div className="h-screen paddings flex flex-col">
        <div ref={fieldRef}>
          <Navbar
            setIsInfoModalOpen={setIsInfoModalOpen}
            setIsStatsModalOpen={setIsStatsModalOpen}
            setIsSettingsModalOpen={setIsSettingsModalOpen}
            setIsFactModalOpen={setIsFactModalOpen}
            lightLogoOverride={lightLogoOR}
            darkLogoOverride={darkLogoOR}
            isDarkMode={isDarkMode}
          />
        </div>
        <div className="pt-2 px-1 pb-6 md:max-w-7xl w-full mx-auto sm:px-6 lg:px-8 flex flex-col">
          <div className="pb-2 grow-0 sm:grow">
            <Grid
              guesses={guesses}
              currentGuess={currentGuess}
              isRevealing={isRevealing}
              currentRowClassName={currentRowClass}
              solution={apiSolution}
            />
            <div className="content-center justify-center z-20">
              <Realistic gameWon={isGameWon} />
            </div>
          </div>
          <Keyboard
            onChar={onChar}
            onDelete={onDelete}
            onEnter={onEnter}
            isModalOpen={
              isSignUpModalOpen ||
              isLoginModalOpen ||
              isLoginWithEmailModalOpen ||
              isLoginWithPinModalOpen
            }
            guesses={guesses}
            isRevealing={isRevealing}
          />
          {/* <AppPopUpModal
            isOpen={isAppPopUpModalOpen}
            handleClose={() => setIsAppPopUpModalOpen(false)}
          /> */}
          <InfoModal
            isOpen={isInfoModalOpen}
            handleClose={() => setIsInfoModalOpen(false)}
            handleOpenSignUp={() => setIsSignUpModalOpen(true)}
          />
          <FactModal
            isOpen={isFactModalOpen}
            handleClose={() => setIsFactModalOpen(false)}
            guesses={guesses}
            gameStats={stats}
            isGameLost={isGameLost}
            isGameWon={isGameWon}
            handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
            isHardMode={isHardMode}
            isDarkMode={isDarkMode}
            isHighContrastMode={isHighContrastMode}
            numberOfGuessesMade={guesses.length}
            description={description}
            link={link}
            linkName={linkName}
            imgSrc={recipeImage}
            solution={apiSolution}
            songUrl={songUrl}
            songTitle={songTitle}
            songArtist={songArtist}
            spotifyUrl={spotifyUrl}
            appleMusicUrl={appleMusicUrl}
            preloadedAudioElement={preloadAudioRef.current}
          />
          <StatsModal
            isOpen={isStatsModalOpen}
            handleClose={() => setIsStatsModalOpen(false)}
            guesses={guesses}
            gameStats={stats}
            userAPIStats={userAPIData}
            isGameLost={isGameLost}
            isGameWon={isGameWon}
            handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
            isHardMode={isHardMode}
            isDarkMode={isDarkMode}
            isHighContrastMode={isHighContrastMode}
            numberOfGuessesMade={guesses.length}
            handleOpenLogin={() => setIsLoginModalOpen(true)}
            handleOpenSignUp={() => setIsSignUpModalOpen(true)}
            logout={() => logout()}
          />
          <SettingsModal
            isOpen={isSettingsModalOpen}
            handleClose={() => setIsSettingsModalOpen(false)}
            isHardMode={isHardMode}
            handleHardMode={handleHardMode}
            isDarkMode={isDarkMode}
            handleDarkMode={handleDarkMode}
            isHighContrastMode={isHighContrastMode}
            handleHighContrastMode={handleHighContrastMode}
          />
          <SignUpModal
            isOpen={isSignUpModalOpen}
            handleClose={() => setIsSignUpModalOpen(false)}
            handleSignUp={() =>
              showSuccessAlert('ACCOUNT CREATED AND LOGGED IN')
            }
            handleError={() => showErrorAlert(`ACCOUNT CREATION FAILED`)}
            setToken={setToken}
          />
          <LoginModal
            isOpen={isLoginModalOpen}
            handleClose={() => setIsLoginModalOpen(false)}
            handleLogin={() => showSuccessAlert('LOGGED IN')}
            handleError={() => showErrorAlert(`ACCOUNT LOGIN FAILED`)}
            handleOpenLoginWithEmailModal={() =>
              setIsLoginWithEmailModalOpen(true)
            }
            setToken={setToken}
          />
          <LoginWithEmailModal
            isOpen={isLoginWithEmailModalOpen}
            handleClose={() => setIsLoginWithEmailModalOpen(false)}
            handleLogin={() => showSuccessAlert('EMAIL SENT')}
            handleError={() => showErrorAlert(`EMAIL COULD NOT BE SENT`)}
            handleOpenLoginWithPinModal={() => setIsLoginWithPinModalOpen(true)}
          />
          <LoginWithPinModal
            isOpen={isLoginWithPinModalOpen}
            handleClose={() => setIsLoginWithPinModalOpen(false)}
            handleSuccess={() => showSuccessAlert('EMAIL SENT')}
            handleFailure={() => showErrorAlert('COULD NOT SEND EMAIL')}
            handleLogin={() => showSuccessAlert('LOGGED IN')}
            handleError={() => showErrorAlert(`INCORRECT PIN`)}
            setToken={setToken}
          />
          <AlertContainer />
        </div>
      </div>
    </>
  )
}

export default Home
