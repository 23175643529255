import { useEffect, useState, useRef } from 'react'
import Countdown from 'react-countdown'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import { AudioPlayer } from '../AudioPlayer'
import { NEW_WORD_TEXT, SHARE_TEXT } from '../../constants/strings'
import { useAlert } from '../../context/AlertContext'

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
  description: string
  link: string
  linkName: string
  imgSrc: string
  solution: string
  songUrl?: string
  songTitle?: string
  songArtist?: string
  spotifyUrl?: string
  appleMusicUrl?: string
  preloadedAudioElement?: HTMLAudioElement | null
}

export const FactModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  numberOfGuessesMade,
  description,
  link,
  linkName,
  imgSrc,
  solution,
  songUrl,
  songTitle,
  songArtist,
  spotifyUrl,
  appleMusicUrl,
  preloadedAudioElement = null,
}: Props) => {
  // Simple state tracking the actual audio element state
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  // Track first open to only try playing once
  const [hasOpened, setHasOpened] = useState(false);
  // Create a ref to access the play button
  const playButtonRef = useRef<HTMLButtonElement>(null);
  // Get the alert context
  const { showError: showErrorAlert } = useAlert();

  // Google Analytics
  useEffect(() => {
    if (typeof window !== 'undefined' && (window as any).gtag) {
      ;(window as any).gtag('js', new Date());
      ;(window as any).gtag('config', 'G-PGCV3ZPNVT');
    }
  }, []);

  // Try to play audio once when modal first opens
  useEffect(() => {
    if (isOpen && preloadedAudioElement && !hasOpened) {
      setHasOpened(true);
      
      // Simply try to play once when modal first opens
      preloadedAudioElement.play()
        .then(() => setIsAudioPlaying(true))
        .catch((error) => {
          console.log('Autoplay blocked:', error.name);
          
          // Show alert to user
          if (error.name === 'NotAllowedError') {
            showErrorAlert('Autoplay blocked by browser. Please tap the play button to hear the audio.', {
              durationMs: 4000 // Show for 4 seconds so user has time to read
            });
          }
        });
    }
  }, [isOpen, preloadedAudioElement, hasOpened, showErrorAlert]);

  // Setup listeners to sync UI with audio state
  useEffect(() => {
    if (!preloadedAudioElement) return;

    const handlePlay = () => setIsAudioPlaying(true);
    const handlePause = () => setIsAudioPlaying(false);
    const handleEnded = () => setIsAudioPlaying(false);

    preloadedAudioElement.addEventListener('play', handlePlay);
    preloadedAudioElement.addEventListener('pause', handlePause);
    preloadedAudioElement.addEventListener('ended', handleEnded);

    return () => {
      preloadedAudioElement.removeEventListener('play', handlePlay);
      preloadedAudioElement.removeEventListener('pause', handlePause);
      preloadedAudioElement.removeEventListener('ended', handleEnded);
    };
  }, [preloadedAudioElement]);

  // Function to toggle play/pause
  const toggleAudio = () => {
    if (!preloadedAudioElement) return;
    
    if (preloadedAudioElement.paused) {
      preloadedAudioElement.play().catch(error => {
        console.error('Play failed:', error);
        
        // Show error to user
        showErrorAlert('Could not play audio. Please try again.', {
          durationMs: 3000
        });
        
        // Reset UI state
        setIsAudioPlaying(false);
      });
    } else {
      preloadedAudioElement.pause();
    }
  };

  const handleLinkClick = (eventName: string, phoodleFact: boolean) => {
    if (typeof window !== 'undefined' && (window as any).gtag) {
      if (phoodleFact) {
        const eventParams = {
          event_category: 'engagement',
          event_label: linkName || 'View a Recipe',
          value: 1,
        }
        ;(window as any).gtag('event', eventName, eventParams)
        console.log('GA event sent:', eventName, eventParams)

        // Open the link in a new tab
        window.open(link, '_blank', 'noopener,noreferrer')
      } else {
        const eventParams = {
          event_category: 'engagement',
          event_label: 'Share Game Result',
          value: 1,
        }
        ;(window as any).gtag('event', eventName, eventParams)
        console.log('GA event sent:', eventName, eventParams)
      }
    } else {
      console.warn('Google Analytics not available')
    }
  }

  if (!isGameLost && !isGameWon) {
    return (
      <BaseModal
        title="SOLUTION"
        isOpen={isOpen}
        handleClose={handleClose}
        isFact={true}
        className={true}
      >
        <div className="font-poppins py-3 font-light dark:text-white">
          <p>
            After playing the game a Phoodle Fact about the word will appear
            here
          </p>
        </div>
      </BaseModal>
    )
  }
  return (
    <BaseModal
      title={solution.toUpperCase()}
      isOpen={isOpen}
      handleClose={handleClose}
      isFact={true}
    >
      <div className="divide-y divide-solid divide-buttonGreen">
        <div className="pb-4">
          <h3 className="font-bold dark:text-white">PHOODLE FACT</h3>
          <div className="dark:text-white py-3 leading-5 font-light font-poppins">
            {description.charAt(0) === '<' ? (
              <span dangerouslySetInnerHTML={{ __html: description }}></span>
            ) : (
              <span>{description}</span>
            )}
          </div>
          {imgSrc.length >= 1 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={imgSrc}
                alt={linkName ? linkName : 'Link Image'}
                style={{ maxHeight: '300px' }}
              />
            </div>
          )}
          {songUrl && songTitle && songArtist && (
            <div className="mt-4 mb-4 w-full max-w-md">
              <AudioPlayer
                songUrl={songUrl}
                songTitle={songTitle}
                songArtist={songArtist}
                isPlaying={isAudioPlaying}
                onEnded={toggleAudio}
                spotifyUrl={spotifyUrl}
                appleMusicUrl={appleMusicUrl}
                preloadedAudioElement={preloadedAudioElement}
                togglePlayPause={toggleAudio}
                playButtonRef={playButtonRef}
              />
            </div>
          )}
          {link.length >= 1 && (
            <div className="dark:text-white py-3 font-light font-poppins">
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="outline-none"
              >
                <button
                  onClick={() => {
                    handleLinkClick('Phoodle_Fact_Link', true)
                  }}
                  type="button"
                  className="font-poppins mt-1 w-1/2 rounded-md border border-transparent shadow-sm px-4 py-3 bg-blueButton text-sm font-bold text-black !outline-none ring-4 ring-offset-2 ring-blueButton sm:text-sm"
                >
                  {linkName ? linkName : 'VIEW A RECIPE'}
                </button>
              </a>
            </div>
          )}
        </div>
        <div>
          {(isGameLost || isGameWon) && (
            <div className="mt-5 sm:mt-6 columns-2 font-poppins dark:text-white">
              <div className="pb-3">
                <h5 className="pb-1">{NEW_WORD_TEXT}</h5>
                <Countdown
                  className="text-lg font-digital text-buttonGreen rounded-lg p-1 px-3 bg-black"
                  date={tomorrow}
                  daysInHours={true}
                />
              </div>
              <button
                type="button"
                className="mt-1 w-full rounded-md border border-transparent shadow-sm px-4 py-3 bg-buttonGreen text-sm font-bold text-black !outline-none ring-4 ring-offset-2 ring-buttonClick sm:text-sm"
                onClick={() => {
                  handleLinkClick('Share_Game_Score', false)
                  shareStatus(
                    guesses,
                    isGameLost,
                    isHardMode,
                    isDarkMode,
                    isHighContrastMode,
                    handleShareToClipboard,
                    solution
                  )
                }}
              >
                {SHARE_TEXT}
              </button>
            </div>
          )}
        </div>
      </div>
    </BaseModal>
  )
}
