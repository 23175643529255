import { useEffect, useRef, useState, RefObject } from 'react'

interface AudioPlayerProps {
  songUrl: string
  songTitle: string
  songArtist: string
  isPlaying: boolean
  onEnded: () => void
  spotifyUrl?: string
  appleMusicUrl?: string
  preloadedAudioElement?: HTMLAudioElement | null
  togglePlayPause?: () => void
  playButtonRef?: RefObject<HTMLButtonElement>
}

export const AudioPlayer = ({
  songUrl,
  songTitle,
  songArtist,
  isPlaying,
  onEnded,
  spotifyUrl,
  appleMusicUrl,
  preloadedAudioElement = null,
  togglePlayPause: externalToggle,
  playButtonRef,
}: AudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const activeAudioElement = preloadedAudioElement || audioRef.current;

  const [isLoaded, setIsLoaded] = useState(false)
  const [progress, setProgress] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [audioDuration, setAudioDuration] = useState(0)
  const [isMuted, setIsMuted] = useState(false)
  
  useEffect(() => {
    const audio = activeAudioElement;
    if (!audio) return;

    const updateStateFromAudio = () => {
      if (!isNaN(audio.duration)) {
        setAudioDuration(audio.duration);
        setIsLoaded(true);
      }
      setCurrentTime(audio.currentTime);
      setProgress(audio.duration ? (audio.currentTime / audio.duration) * 100 : 0);
      setIsMuted(audio.muted);
    };

    updateStateFromAudio();

    audio.addEventListener('loadedmetadata', updateStateFromAudio);
    audio.addEventListener('durationchange', updateStateFromAudio);
    audio.addEventListener('timeupdate', updateStateFromAudio);
    audio.addEventListener('volumechange', updateStateFromAudio);
    audio.addEventListener('play', updateStateFromAudio);
    audio.addEventListener('pause', updateStateFromAudio);
    audio.addEventListener('ended', updateStateFromAudio);

    return () => {
      audio.removeEventListener('loadedmetadata', updateStateFromAudio);
      audio.removeEventListener('durationchange', updateStateFromAudio);
      audio.removeEventListener('timeupdate', updateStateFromAudio);
      audio.removeEventListener('volumechange', updateStateFromAudio);
      audio.removeEventListener('play', updateStateFromAudio);
      audio.removeEventListener('pause', updateStateFromAudio);
      audio.removeEventListener('ended', updateStateFromAudio);
    }
  }, [activeAudioElement]);

  useEffect(() => {
    const audio = activeAudioElement;
    if (!audio) return;
    audio.muted = isMuted
  }, [isMuted, activeAudioElement]);

  const togglePlayPause = () => {
    if (externalToggle) {
      externalToggle();
    } else {
      onEnded();
    }
  }

  const toggleMute = () => {
    setIsMuted(!isMuted)
  }

  return (
    <div className="audio-player p-3 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      <audio ref={audioRef} src={songUrl} preload="none" style={{ display: 'none' }} />

      <div className="flex flex-col">
        <div className="flex justify-between items-center mb-2">
          <div className="font-bold text-sm dark:text-white">{songTitle}</div>
          <div className="text-xs text-gray-600 dark:text-gray-300">
            {songArtist}
          </div>
        </div>

        <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2 mb-2">
          <div
            className="bg-darkerGreen h-2 rounded-full"
            style={{ width: `${progress}%` }}
          />
        </div>

        <div className="flex justify-between text-xs text-gray-600 dark:text-gray-300 mb-2">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(audioDuration)}</span>
        </div>

        <div className="flex justify-center gap-4 mt-1 mb-2">
          <button
            ref={playButtonRef}
            onClick={togglePlayPause}
            disabled={!isLoaded}
            className="bg-darkerGreen text-white w-10 h-10 rounded-full flex items-center justify-center hover:bg-green-700 transition-colors"
            aria-label={isPlaying ? 'Pause song' : 'Play song'}
          >
            {isPlaying ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M5.75 3a.75.75 0 00-.75.75v12.5c0 .414.336.75.75.75h1.5a.75.75 0 00.75-.75V3.75A.75.75 0 007.25 3h-1.5zM12.75 3a.75.75 0 00-.75.75v12.5c0 .414.336.75.75.75h1.5a.75.75 0 00.75-.75V3.75a.75.75 0 00-.75-.75h-1.5z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
              </svg>
            )}
          </button>

          <button
            onClick={toggleMute}
            disabled={!isLoaded}
            className="bg-darkerGreen text-white py-1 px-4 rounded-full text-sm hover:bg-green-700 transition-colors w-24"
            aria-label={isMuted ? 'Unmute song' : 'Mute song'}
          >
            {isMuted ? 'Unmute' : 'Mute'}
          </button>
        </div>

        {(spotifyUrl || appleMusicUrl) && (
          <div className="flex justify-center space-x-4 mt-2">
            {spotifyUrl && (
              <a
                href={spotifyUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm flex items-center text-gray-600 hover:text-darkerGreen transition-colors dark:text-gray-300"
              >
                <img
                  src="/Spotify_icon_green.svg"
                  alt="Spotify"
                  className="w-5 h-5 mr-1"
                />
                Spotify
              </a>
            )}
            {appleMusicUrl && (
              <a
                href={appleMusicUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm flex items-center text-gray-600 hover:text-darkerGreen transition-colors dark:text-gray-300"
              >
                <img
                  src="/Apple_Music_icon_green.svg"
                  alt="Apple Music"
                  className="w-5 h-5 mr-1"
                />
                Apple Music
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const formatTime = (seconds: number): string => {
  const mins = Math.floor(Math.max(0, seconds) / 60)
  const secs = Math.floor(Math.max(0, seconds) % 60)
  return `${mins}:${secs < 10 ? '0' : ''}${secs}`
}
